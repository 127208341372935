@font-face {
  font-family: "Gloria";
  src: local("Gloria"),
    url(/public/fonts/Gloria_Hallelujah/GloriaHallelujah-Regular.ttf)
      format("truetype");
}
@font-face {
  font-family: "WorkSans";
  src: local("Gloria"),
    url(/public/fonts/Work_Sans/static/WorkSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "WorkSans";
  font-weight: bold;
  src: local("Gloria"),
    url(/public/fonts/Work_Sans/static/WorkSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Glory";
  src: local("Glory"),
    url(/public/fonts/Give_You_Glory/GiveYouGlory-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url(/public/fonts/Jost/static/Jost-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(/public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format("truetype");
}
